import React from "react";
import homeShop1 from '../../../assets/images/jpg/homeShop1.jpg';
import homeShop2 from '../../../assets/images/jpg/homeShop2.jpg';
import homeShop3 from '../../../assets/images/jpg/homeShop3.jpg';
import homeShop4 from '../../../assets/images/jpg/homeShop4.jpg';
import homeShop5 from '../../../assets/images/jpg/homeShop5.jpg';

const ShopHome = () => {
    return(
        <div className="hp-shop">
            <div className="hp-shop__head">
                <h2 className="hp-shop__title">Nos goodies</h2>
            </div>
            <div className="hp-shop__block">
                <div className="hp-shop__content">
                    <div className="hp-shop__card" id="flip-card">
                    <div className="hp-shop__front">
                        <img className="hp-shop__bigpic" src={homeShop1} alt="T-shirt one"/>
                    </div>
                    <div className="hp-shop__back">
                        <span className="hp-shop__back--desc">
                            Nos t-shirts représentent l’essence de Just A Cause. Nous avons choisi un modèle blanc : le « basique » indispensable dans tout dressing. <br/>
                            Nos t-shirts sont produits en coton bio vegan labellisés Oeko-Tex 100, permettant de protéger notre peau ainsi que notre planète ! <br/>
                            Sur la poitrine, côté cœur, nous avons souhaité vous offrir le marquage le plus noble : la broderie, créée en polyester recyclé, une fois de plus pour protéger notre environnement. <br/>
                            Cette broderie est disponible en 6 couleurs, symboles des causes que nous défendons. <br/>
                            Votre t-shirt engagé sera disponible prochainement sur notre plateforme de crowdfunding. <br/>
                            Suivez-nous sur nos réseaux sociaux pour rester informés du lancement de la campagne ! <br/>
                        </span>
                    </div>
                    </div>
                </div>
                <div className="hp-shop__subcontent">
                    <img className="hp-shop__smallpic" src={homeShop2} alt="T-shirt two"/>
                    <img className="hp-shop__smallpic" src={homeShop3} alt="T-shirt three"/>
                    <img className="hp-shop__smallpic" src={homeShop4} alt="T-shirt four"/>
                    <img className="hp-shop__smallpic" src={homeShop5} alt="T-shirt five"/>
                </div>
            </div>
        </div>
    )
}

export default ShopHome;