import React from "react";
import HeaderHome from "./subComponents/Home/HeaderHome";
import NewsHome from "./subComponents/Home/NewsHome";
import ShopHome from "./subComponents/Home/ShopHome";
import ContactHome from "./subComponents/Home/ContactHome"
import '../css/modules/Home.css';

const Home = () => {
    return(
        <div className="hp">
            <HeaderHome />
            <NewsHome />
            <ShopHome />
            <ContactHome />
        </div>
    )
}

export default Home;