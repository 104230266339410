import React, { useEffect } from "react";
import '../css/modules/LegalNotice.css'

const LegalNotice = () => {

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
      }, [])

    return(
        <div className="ln">
            <div className="ln-ct">
                <h2 className="ln-ct__title">Mentions Légales</h2>
                    <h3 className="ln-ct__subtitle">1. PROPRIÉTAIRE DU SITE</h3>
                    <p className="ln-ct__p">Association Just A Cause <br/>
                        11 RUE ISIDORE LOUVEAU <br/>
                        35700 RENNES <br/>
                    </p>

                    <h3 className="ln-ct__subtitle">2. CONCEPTION ET CRÉATION DU SITE</h3>

                    <p className="ln-ct__p">Association Just A Cause, 11 Rue Isidore Louveau, 35700 Rennes</p>

                    <h3 className="ln-ct__subtitle">3. HÉBERGEMENT</h3>

                    <p className="ln-ct__p">L'hébergement du site justacause.fr est effectué sur les serveurs OVH et Heroku. </p>

                    <h3 className="ln-ct__subtitle">4. PROPRIÉTÉ INTELLECTUELLE</h3>

                    <p className="ln-ct__p">Les images, informations au sens large, marques, 
                        logos et noms de domaine figurant sur le Site sont la 
                        propriété de Just A Cause ou font l’objet d’une autorisation 
                        d’utilisation exclusive. <br/>
                        Toute utilisation sous forme de 
                        reproduction, modification, chargement informatique, 
                        dénaturation, transmission ou distribution partielle ou 
                        intégrale d’un document ou d’une information figurant sur 
                        le Site est strictement interdite sauf autorisation écrite 
                        et préalable de Just A Cause à l’exception d’une copie à usage 
                        privé et non-commercial et sous réserve du respect des droits 
                        de propriété intellectuelle et de tout autre droit de propriété 
                        dont il est fait mention. <br/>
                        La mention suivante devra figurer sur 
                        toute copie de tout ou partie du contenu du Site « Copyright 
                        Just A Cause. , Tous droits réservés ». <br/>
                        Toute cession ou licence d’un des éléments constitutifs du Site 
                        est soumise à l’autorisation préalable et écrite de Just A Cause,
                        tout contrevenant s’exposant à des poursuites judiciaires. <br/>
                        De plus, tout contrevenant accepte de garantir et d’indemniser 
                        Just A Cause pour toutes poursuites, coûts et dépenses engagées
                        par Just A Cause suite à l’utilisation du Site en infraction 
                        avec les présentes mentions légales.
                    </p>


                    <h3 className="ln-ct__subtitle">5. INFORMATIONS PERSONNELLES</h3>


                    <p className="ln-ct__p">Conformément aux dispositions de la loi informatique et libertés 
                        du 6 janvier 1978, vous disposez d’un droit de modification, 
                        de rectification et de suppression des données à caractère 
                        personnel vous concernant en vous adressant à 
                        info.justacause@gmail.com.
                    </p>


                    <h3 className="ln-ct__subtitle">6. SERVICE D’INFORMATION</h3>


                    <p className="ln-ct__p">Si vous êtes abonnés à des services d’information par courrier 
                        électronique (« newsletter »), vous pouvez demander à ne plus 
                        recevoir ces courriers soit comme indiqué ci-dessus, soit en 
                        suivant les instructions figurant en fin de chacun de ces 
                        courriers, lorsque vous les recevez.
                    </p>


                    <h3 className="ln-ct__subtitle">7. COOKIES</h3>


                    <p className="ln-ct__p">Un cookie peut s’installer automatiquement sur votre logiciel 
                        de navigation lors de vos visites sur le site www.justacause.fr. 
                        Le cookie est un bloc de données qui ne permet pas d’identifier 
                        l’utilisateur mais sert à enregistrer des informations relatives 
                        à la navigation de celui-ci sur le site. Vous pouvez vous opposer 
                        à l’enregistrement de ces “cookies” en configurant votre 
                        ordinateur selon les modalités détaillées sur le 
                        site <a target="_blank" rel="noopener noreferrer" className="ln-ct__link" href="http://www.cnil.fr">http://www.cnil.fr.</a>
                    </p>
            </div>
        </div>
    );
};

export default LegalNotice;