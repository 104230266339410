import React from "react";
import errorPic from "../../assets/images/jpg/404.jpg";
import '../../css/error/Error404.css';

const Error404 = () => {
    return(
        <div className="e404">
            <h2 className="e404__title">PAGE 404</h2>
            <h3 className="e404__subtitle">La page que vous recherchez est introuvable</h3>
            <div className="e404__desc">Code d'erreur : 404</div>
            <img className="e404__pic" src={errorPic} alt="Banner JAC team"></img>
        </div>
    )
}

export default Error404;