import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import '../css/modules/PartnerB.css';

const PartnerB = () => {

    const [ hookPartner, setHookPartner ] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL + 'partnerb')
            .then((res) => res.data)
            .then((data) => setHookPartner(data));
	}, []);


    return(
        <div className="ppb">
            <div className="ppb__content">
                <ul className="ppb-nav">
                    <li className="ppb-nav__item ppb-nav__item--one">
                        <Link to="/partenaires-associations">Associations</Link>
                    </li>
                    <li className="ppb-nav__item ppb-nav__item--two">
                        <Link to="/partenaires-entreprises">Entreprises</Link>
                    </li>
                </ul>
                {/* <ul className="ppb-slider">
                    <li className="ppb-slider__i">
                        <img className="ppb-slider__pic"/>
                    </li>
                </ul> */}
                <ul className="ppb__block">
                    {hookPartner.map( ( $partner, key ) => (
                        <li className="ppb__i" key={key}>
                            <img className="ppb__i__img" src={$partner.avatar} alt="Logo association"/>
                            <div className="ppb__i__head">
                                <h4 className="ppb__i__name">{$partner.name}</h4>
                                <h5 className="ppb__i__subtitle">{$partner.subtitle}</h5>
                                <div className="ppb__i__content">
                                    <a className="ppb__i__link" href={$partner.content}>{$partner.name}</a>
                                </div>
                                {/* <a className="ppb__i__link" href={$partner.content}>{$partner.name}</a> */}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PartnerB;