import React from "react";
import homeContact from "../../../assets/images/jpg/homeContact.jpg"
import { displayModal } from "../../items/Modal";

const ContactHome = () => {
    return(
        <div className="hp-contact">
            <div className="hp-contact__pic">
                <img className="hp-contact__img" src={homeContact} alt="Just a cause team"/>
            </div>
            <div className="hp-contact__txt">
                <h2 className="hp-contact__title">Nous contacter</h2>
                <h3 className="hp-contact__subtitle">L’équipe de Just A Cause est à 
                    ton écoute ! Une remarque, une suggestion à nous faire ? 
                    Une information complémentaire à nous demander ?
                </h3>
                <div className="hp-contact__desc">
                    N’hésite pas à remplir le formulaire
                    ci-dessous. Nous ferons le maximum pour te répondre dans
                    de plus brefs délais.
                </div>
                <div className="hp-contact__frame">
                    <input type="text" className="hp-contact__input" placeholder="Email Address"/>
                    <button type="submit" className="hp-contact__btn" >SEND</button>
                </div>
            </div>
        </div>
    )
}

export default ContactHome;