import React, { useState, useEffect } from "react";
import axios from "axios";
import '../css/modules/History.css';
import historyPic1 from "../assets/images/jpg/historyPage.jpg"
import historyPic2 from "../assets/images/jpg/historyPage2.jpg"
import pictoBee from "../assets/images/svg/bee.svg"
import pictoWave from "../assets/images/svg/wave.svg"
import pictoTree from "../assets/images/svg/tree.svg"
import pictoPet from "../assets/images/svg/pet.svg"
import pictoDoubleSexe from "../assets/images/svg/double_sexe.svg"
import pictoGirl from "../assets/images/svg/girl.svg"

const History = () => {

    const [ hookHistory, setHookHistory ] = useState([]);
    const [ hookTeam, setHookTeam ] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL + 'history')
            .then((res) => res.data)
            .then((data) => setHookHistory(data));
        axios.get(process.env.REACT_APP_URL + "team")
            .then((res) => res.data)
            .then((data) => setHookTeam(data));
	}, []);

    return(
        <div className="hip">
            <div className="hip__content">
                <div className="hip__block">
                    <img className="hip__pic" src={historyPic1} alt="Team banner"/>
                    {hookHistory.map( ( $history, key ) => (
                        <div className="hip__i" key={key}>
                            <h2 className="hip__i__title">{$history.title}</h2>
                            <ul className="hip__i__content">
                                <li className="hip__item">
                                    <div className="hip__i__content hip__i__content--top" dangerouslySetInnerHTML={{ __html: $history.content_top}}/>
                                    <div className="hip__i__block">
                                        <img className="hip__i__picto hip__i__picto--bee" src={pictoBee} alt="Picto bee"/>
                                        <img className="hip__i__picto hip__i__picto--wave" src={pictoWave} alt="Picto wave"/>
                                        <img className="hip__i__picto hip__i__picto--tree" src={pictoTree} alt="Picto tree"/>
                                    </div>
                                </li>
                                <li className="hip__item">
                                    <div className="hip__i__content hip__i__content--mid" dangerouslySetInnerHTML={{ __html: $history.content_mid}} />
                                    <div className="hip__i__block">
                                        <img className="hip__i__picto hip__i__picto--pet" src={pictoPet} alt="Picto pet"/>
                                        <img className="hip__i__picto hip__i__picto--sexe" src={pictoDoubleSexe} alt="Picto sexe"/>
                                        <img className="hip__i__picto hip__i__picto--girl" src={pictoGirl} alt="Picto girl"/>
                                    </div>
                                </li>
                                <li className="hip__item">
                                    <div className="hip__item__block">
                                        <div className="hip__i__content hip__i__content--bot" dangerouslySetInnerHTML={{ __html: $history.content_bot}}/>
                                        <img className="hip__i__picture" src={historyPic2} alt="Somebody"/>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    ))}
                </div>
                <div className="hip__t">
                    <h2 className="hip__t__title">L'équipe</h2>
                    <ul className="hip__t__list">
                        {hookTeam.map( ( $team, key) => (
                            <li className="hip__t__i" key={key}>
                                <img className="hip__t__pic" src={$team.avatar} alt="Team member"/>
                                <div className="hip__t__block">
                                    <h3 className="hip__t__subtitle">{ $team.name }</h3>
                                    <div className="hip__t__desc">{ $team.role }</div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default History;