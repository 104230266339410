import React from "react";
import Logo from "../../assets/images/svg/logo.svg";
import FbIcon from "../../assets/images/svg/facebook-dark.svg";
import IgIcon from "../../assets/images/svg/instagram-dark.svg";
import PtIcon from "../../assets/images/svg/pinterest-dark.svg";
import { Link } from "react-router-dom";
import { displayModal } from "../items/Modal";
import '../../css/inline/Header.css';

const Header = () => {

	//EDIT CONTENT

	const navList = [
		{link: "/histoire", name: "Notre histoire"},
		{link: "/partenaires-associations", name: "Partenaires"},
		{link: "/evenements", name: "Nos évènements"},
		{link: "/articles", name: "Blog"},
		{link: "/contact", name: "Contact"}
	]

	const titleSite = "Just A Cause";

	const displayBurger = () => {
		const $burger = document.getElementById('burger');
		const $menu = document.getElementById('burger-menu');
		const $header = document.querySelector('.header');
		$burger.classList.toggle('burger-is-open');
		$menu.classList.toggle('menu-is-open');
		$header.classList.toggle('sticky');
	}

	const closeBurger = () => {
		const $burger = document.getElementById('burger');
		const $menu = document.getElementById('burger-menu');
		const $header = document.querySelector('.header');
		$burger.classList.remove('burger-is-open');
		$menu.classList.remove('menu-is-open');
		$header.classList.remove('sticky');
	}

	const facebook = {
        url: "https://www.facebook.com/Just-A-Cause-433212654170236", 
        icon: FbIcon
    } 
    const instagram = {
        url: "https://www.instagram.com/associationjustacause/",
        icon: IgIcon
    };
    const pinterest = {
        url: "https://www.pinterest.fr/Just_a_cause/boards/",
        icon: PtIcon
    }

	const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

	return(
		<header className="header">
			<div className="header__content">
				<div className="header__top">
					<Link to="/" className="header__block" onClick={() => topFunction()}>
						<img src={Logo} className="header__logo" alt="Just a cause Logo"/>
						<h1 className="header__title">{titleSite}</h1>
					</Link>
				</div>
				<ul className="header__bot" id="burger-menu">
					{navList.map((x, key) => 
						<li className="header__item" key={key} >
							<Link to={x.link} className="header__link" onClick={() => closeBurger()}>{x.name}</Link>
						</li>
					)}
				</ul>
				<div className="header-burger" id="burger" onClick={() => displayBurger()}>
					<span className="header-burger__item header-burger__item--top">1</span>
					<span className="header-burger__item header-burger__item--mid">2</span>
					<span className="header-burger__item header-burger__item--bot">3</span>
				</div>
			</div>
			<div className="header-picto">
				<a target="_blank" rel="noopener noreferrer" href={facebook.url} className="header__link">
					<img className="social-network social-network--facebook header-facebook" src={facebook.icon} alt="Picto facebook"/>
				</a>
				<a target="_blank" rel="noopener noreferrer" href={instagram.url} className="header__link">
					<img className="social-network social-network--instagram header-instagram" src={instagram.icon} alt="Picto instagram"/>
				</a>
				{/* <div className="header__link">
					<img className="social-network social-network--pinterest header-pinterest" src={pinterest.icon} alt="Picto pinterest"/>
				</div> */}
				{/* <a target="_blank" rel="noopener noreferrer" href={pinterest.url} className="header__link">
					<img className="social-network social-network--pinterest header-pinterest" src={pinterest.icon} alt="Picto pinterest"/>
				</a> */}
			</div>
		</header>
	)
}

export default Header;