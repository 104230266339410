import React, { useEffect } from "react";
import '../css/modules/TermsOfServices.css';

const TermsOfService = () => {
    
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
      }, [])

    return(
        <div className="ts">
            <div className="ts-ct">

                <h2 className="ts-ct__title">Conditions générales d'utilisation du site justacause.fr</h2>

                <h3 className="ts-ct__subtitle">Article 1 : Objet</h3>
                
                <p className="ts-ct__p">Les présentes « conditions générales d'utilisation » ont pour objet l'encadrement juridique de 
                l’utilisation du site justacause.fr et de ses services.
                
                <br/>Ce contrat est conclu entre :
                Le gérant du site internet, ci-après désigné « l’Éditeur »,

                <br/>Toute personne physique ou morale souhaitant accéder au site et à ses services, ci-après appelé 
                « l’Utilisateur ».

                <br/>Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur, et son accès au 
                site vaut acceptation de ces conditions.</p>

                <h3 className="ts-ct__subtitle">Article 2 : Mentions légales</h3>

                <p className="ts-ct__p">Le site Justacause.fr est édité par l’association Just A Cause , domicilié au 11 rue Isidore Louveau 
                35700 Rennes.</p>

                <h3 className="ts-ct__subtitle">Article 3 : accès aux services</h3>

                <p className="ts-ct__p">L’Utilisateur du site justacause.fr a accès aux services suivants :
                Accès au blog et aux actualités sur Justacause.fr
                Envoie de demande d’inscription à la newsletter de l’association Just A Cause
                
                <br/>Tout Utilisateur ayant accès à internet peut accéder gratuitement et depuis n’importe où au site. 
                Les frais supportés par l’Utilisateur pour y accéder (connexion internet, matériel informatique, 
                etc.) ne sont pas à la charge de l’Éditeur.
                
                <br/>Le site et ses différents services peuvent être interrompus ou suspendus par l’Éditeur, notamment 
                à l’occasion d’une maintenance, sans obligation de préavis ou de justification.</p>

                <h3 className="ts-ct__subtitle">Article 4 : Responsabilité de l’Utilisateur</h3>

                <p className="ts-ct__p">L’Utilisateur assume l’entière responsabilité de l’utilisation qu’il fait des informations et contenus 
                présents sur le site Justacause.fr.
                
                <br/>Tout usage du service par l'Utilisateur ayant directement ou indirectement pour conséquence des 
                dommages doit faire l'objet d'une indemnisation au profit du site.
                Le membre s’engage à tenir des propos respectueux des autres et de la loi et accepte que ces 
                publications soient modérées ou refusées par l’Éditeur, sans obligation de justification. 
                
                <br/>En publiant sur le site, l’Utilisateur cède à l’Éditeur le droit non exclusif et gratuit de représenter, 
                reproduire, adapter, modifier, diffuser et distribuer sa publication, directement ou par un tiers 
                autorisé.
                
                <br/>L’Éditeur s'engage toutefois à citer le membre en cas d’utilisation de sa publication</p>
               
                <h3 className="ts-ct__subtitle">Article 5 : Responsabilité de l’Éditeur</h3>
                
                <p className="ts-ct__p">Tout dysfonctionnement du serveur ou du réseau ne peut engager la responsabilité de l’Éditeur.
                
                <br/>De même, la responsabilité du site ne peut être engagée en cas de force majeure ou du fait 
                imprévisible et insurmontable d'un tiers.Le site Justacause.fr s'engage à mettre en œuvre tous les moyens nécessaires pour garantir la 
                sécurité et la confidentialité des données. Toutefois, il n’apporte pas une garantie de sécurité 
                totale.
                
                <br/>L’Éditeur se réserve la faculté d’une non-garantie de la fiabilité des sources, bien que les 
                informations diffusées sur le site soient réputées fiables.</p>
                
                <h3 className="ts-ct__subtitle">Article 6 : Propriété intellectuelle</h3>
                
                <p className="ts-ct__p">Les contenus du site Justacause.fr (logos, textes, éléments graphiques, vidéos, etc.) sont 
                protégés par le droit d’auteur, en vertu du Code de la propriété intellectuelle.
                
                <br/>L’Utilisateur devra obtenir l’autorisation de l’éditeur du site avant toute reproduction, copie ou 
                publication de ces différents contenus.
                
                <br/>Ces derniers peuvent être utilisés par les utilisateurs à des fins privées ; tout usage commercial 
                est interdit.

                <br/>L'Utilisateur est entièrement responsable de tout contenu qu’il met en ligne et il s’engage à ne pas 
                porter atteinte à un tiers.

                <br/>L’Éditeur du site se réserve le droit de modérer ou de supprimer librement et à tout moment les 
                contenus mis en ligne par les utilisateurs, et ce sans justification.</p>

                <h3 className="ts-ct__subtitle">Article 7 : Données personnelles</h3>

                <p className="ts-ct__p">Justacause.fr garantie le respect de la vie privée de l’utilisateur, conformément à la loi n°78-17 du 
                6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.

                <br/>En vertu des articles 39 et 40 de la loi en date du 6 janvier 1978, l'Utilisateur dispose d'un droit 
                d'accès, de rectification, de suppression et d'opposition de ses données personnelles. </p>

                <p className="ts-ct__p">L'Utilisateur exerce ce droit via :
                <br/>Un formulaire de contact ;
                <br/>Par mail à contact@justacause.fr;
                <br/>Par voie postale au 11 rue Isidore Louveau, 35700 Rennes </p>

                <h3 className="ts-ct__subtitle">Article 8 : Liens hypertextes</h3>

                <p className="ts-ct__p">Les domaines vers lesquels mènent les liens hypertextes présents sur le site n’engagent pas la 
                responsabilité de l’Éditeur de Justacause.fr, qui n’a pas de contrôle sur ces liens.
                
                <br/>Il est possible pour un tiers de créer un lien vers une page du site Justacause.fr sans autorisation 
                expresse de l’éditeur.</p>

                <h3 className="ts-ct__subtitle">Article 9 : Évolution des conditions générales d’utilisation</h3>

                <p className="ts-ct__p">Le site Justacause.fr se réserve le droit de modifier les clauses de ces conditions générales 
                d’utilisation à tout moment et sans justification.</p>

                <h3 className="ts-ct__subtitle">Article 10 : Durée du contrat</h3>

                <p className="ts-ct__p">La durée du présent contrat est indéterminée. Le contrat produit ses effets à l'égard de 
                l'Utilisateur à compter du début de l’utilisation du service.</p>

                <h3 className="ts-ct__subtitle">Article 11 : Droit applicable et juridiction compétenteLe présent contrat dépend de la législation française.</h3> 

                <p className="ts-ct__p">En cas de litige non résolu à l’amiable entre l’Utilisateur et l’Éditeur, l’affaire sera porté devant les 
                tribunaux de Rennes compétents pour régler le contentieux.</p>
            </div>
        </div>
    );
};

export default TermsOfService;