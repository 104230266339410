import React from "react";
import homeLeft from "../../../assets/images/jpg/homeLeft.jpg";
import homeMid from "../../../assets/images/jpg/homeMid.jpg";
import homeRight from "../../../assets/images/jpg/homeRight.jpg";
import { displayModal } from '../../items/Modal';

const HeaderHome = () => {
    return(
        <div className="hp-header">
            <div className="hp-header__content">
                <img src={homeLeft} className="hp-header__pic hp-header__pic--left" alt="List of product"/>
                <img src={homeMid} className="hp-header__pic hp-header__pic--mid" alt="List of product"/>
                <img src={homeRight} className="hp-header__pic hp-header__pic--right" alt="List of product"/>
            </div>
            <div className="hp-header__block">
                <h2 className="hp-header__title">SOUTENEZ VOTRE CAUSE</h2>
                <div className="hp-header__desc">Parce que le monde que l'on connaît regorge de causes à défendre et qu'il n'est pas toujours facile d'en choisir une seule, Just A Cause vous propose de soutenir des associations qui font la différence au travers de t-shirts bien pensés.
                <br/>Offrez-vous un t-shirt engagé !</div>
                <a href="#home" className="hp-header__link" onClick={() => displayModal()}>soutenir une association</a>
            </div>
        </div> 
    )
}
export default HeaderHome;