import React, { useState, useEffect } from "react";
import axios from "axios";
import { displayModal } from "../components/items/Modal";
import '../css/modules/Contact.css';

const Contact = () => {

    const title = "Nous contacter";
    const [ hookContact, setHookContact ] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL + 'contact')
            .then((res) => res.data)
            .then((data) => setHookContact(data));
    }, [])

    return(
        <div className="cp">
            <div className="cp__content">
                <h2 className="cp__title">{title}</h2>
                <div className="cp__block">
                    {hookContact.map( ( $contact, key ) => (
                        // eslint-disable-next-line
                        <a className="cp__mail" href={"mailto:" + $contact.link} key={key}>{$contact.content}</a>
                    ))}
                </div>
                <div className="cp__content">
                    <h3 className="cp__subtitle">SOUTENEZ VOTRE CAUSE</h3>
                    {/* <a className="hp-header__link cp__link" href="#" onClick={() => displayModal()}>Soutenir une association</a> */}
                    <div className="hp-header__link cp__link" href="#">Soutenir une association</div>
                </div>
            </div>
        </div>
    )
}

export default Contact;