import React from 'react';
import Routes from "./routes/Routes";
import './css/fonts/Fonts.css';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;