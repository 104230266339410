import React, { useState } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import { useAuth, AuthContext } from './Auth';
import '../../css/auth/Login.css'

const Login = () => {
	const userHook = 						React.useContext(AuthContext);
	const [ isLoggedIn, setIsLoggedIn ] = 	useState(false);
	const [ username, setUsername ] = 		useState('');
	const [ pass, setPass ] = 				useState('');
	const { setAuthTokens } = 				useAuth();

	const postLogin = () => {
		axios
			.post(`http://localhost:5000/api/auth/login`, {
				username,
				pass
			})
			.then((res) => {
				//  userHook.setAuthTokens(res.data.token);
				setAuthTokens(res.data.token);
				userHook.setUserInfo(res.data.user[0]);
				setIsLoggedIn(true);
			});
	};

	if (isLoggedIn) {
		return <Redirect to="/AdminPanelHome" />;
	}

	return (
		<div className="login">
			<div className="login__block">
				<div className="login__title">
					<h3 className="login--title">rFactory BackOffice</h3>
				</div>	
				<form className="login__form">
					<input name="username" className="login__input" type="username" placeholder="Nom de compte" onChange={(e) => setUsername(e.target.value)} />
					<input name="password" className="login__input" type="password" placeholder="Mot de passe" onChange={(e) => setPass(e.target.value)} />
				</form>
				<div className="login__content">
					<button className="login__btn" onClick={() => postLogin()}>
						Connexion
					</button>
				</div>
			</div>
		</div>
	);
};

export default Login;