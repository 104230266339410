import React from "react";
import Logo from "../../assets/images/svg/logo-light.svg"
import FbIcon from "../../assets/images/svg/facebook.svg";
import IgIcon from "../../assets/images/svg/instagram.svg";
import PtIcon from "../../assets/images/svg/pinterest.svg";
import { Link } from "react-router-dom";
import "../../css/inline/Footer.css";

const Footer = () => {

    const facebook = {
        url: "https://www.facebook.com/Just-A-Cause-433212654170236", 
        icon: FbIcon
    } 

    const instagram = {
        url: "https://www.instagram.com/associationjustacause/",
        icon: IgIcon
    }

    const pinterest = {
        url: "https://www.pinterest.fr/Just_a_cause/boards/",
        icon: PtIcon
    }

    const topFunction = () => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    const displayCookie = () => {
        const $modal = document.querySelector('.cmodal-bg');
        const isDisplay = $modal.classList.contains('cmodal-open') ? true : false;
        document.querySelector('.footer__cookie').classList.toggle('accepted');
        if( isDisplay ) {
            $modal.classList.remove('cmodal-open')
        }
    }

    const displayCookieModal = () => {
        document.getElementById('cookie-modal-bg').classList.toggle('cmodal-open');
    }

    return(
        <footer className="footer">
            <ul className="footer__content">
                <li className="footer__item footer__item-logo" onClick={() => topFunction()}>
                    <Link to='/'>
                        <img id="footer-logo" className="footer__logo" src={Logo} alt="Logo JAC"/>
                    </Link>
                </li>
                <li className="footer__item">
                    <div className="footer__desc">
                        <Link to="/mentions-legales">Mentions Légales</Link><br/>
                        <Link to="/conditions-generales">Conditions Générales d'Utilisation</Link>
                    </div>
                </li>
                <li className="footer__item">
                    <div className="footer__desc">
                    <a href="mailto:contact@justacause.fr">contact@justacause.fr</a>
                    </div>
                </li>
                <li className="footer__item">
                    <a target="_blank" rel="noopener noreferrer" href={facebook.url} className="footer__link">
                        <img className="social-network social-network--facebook " src={facebook.icon} alt="Picto facebook"/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={instagram.url} className="footer__link">
                        <img className="social-network social-network--instagram" src={instagram.icon} alt="Picto instagram"/>
                    </a>
                    {/* <div className="footer__link">
                        <img className="social-network social-network--pinterest" src={pinterest.icon} alt="Picto pinterest"/>
                    </div> */}
                    {/* <a target="_blank" rel="noopener noreferrer" href={pinterest.url} className="footer__link">
                        <img className="social-network social-network--pinterest" src={pinterest.icon} alt="Picto pinterest"/>
                    </a> */}
                </li>
            </ul>
            <div className="footer__copyright">
                2022 © Just A Cause. All rights reserved
            </div>
            <div className="footer__cookie">
                <div className="footer__cookie__txt">
                    Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web.
                    <span className="footer__cookie__more" onClick={() => displayCookieModal()}>En savoir plus</span>
                </div>
                <div className="footer__cookie__container">
                    <div className="footer__cookie__close" onClick={() => displayCookie()}>Accepter</div>
                    <div className="footer__cookie__close footer__cookie__denied" onClick={() => displayCookie()}>Tout Refuser</div>
                </div>
            </div>

            {/* Modal Cookie */}

            <div className="cmodal-bg" id="cookie-modal-bg">
                <div className="cmodal" id="cookie-modal">

                    <h2 className="cmodal__title">POLITIQUE DE CONFIDENTIALITÉ</h2>
                    <p className="cmodal__txt">Association Just A Cause </p>
                    <p className="cmodal__txt">Type de site : Association</p>


                    <h3 className="cmodal__subtitle">Le but de cette politique de confidentialité</h3>
                    <p className="cmodal__txt">Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site des données personnelles que nous recueillerons ainsi que les informations suivantes, 
                    le cas échéant : </p> 
                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. Les données personnelles que nous recueillerons </li>
                        <li className="cmodal__item">b. L'utilisation des données recueillies </li>
                        <li className="cmodal__item"> c. Qui a accès aux données recueillies </li>
                        <li className="cmodal__item">d. Les droits des utilisateurs du site </li>
                        <li className="cmodal__item">e. La politique de cookies du site Cette politique de confidentialité fonctionne parallèlement aux conditions générales d'utilisation de notre site. Lois applicables </li>
                    </ul>

                    <h3 className="cmodal__subtitle">Lois applicables </h3>
                    <p className="cmodal__txt">Conformément au Règlement général sur la protection des données (RGPD), cette politique de confidentialité est conforme aux règlements suivants. 
                    Les données à caractère personnel doivent être : </p>
                    
                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. traitées de manière licite, loyale et transparente au regard de la personne concernée (licéité, loyauté, transparence) ; </li>
                        <li className="cmodal__item"> b. collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées ultérieurement d'une manière incompatible avec ces finalités; 
                        le traitement ultérieur à des fins archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques n'est pas considéré, 
                        conformément à l'article 89, paragraphe 1, comme incompatible avec les finalités initiales (limitation des finalités) ; </li>
                        <li className="cmodal__item">c. adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées (minimisation des données) ; </li>
                        <li className="cmodal__item">d. exactes et, si nécessaire, tenues à jour; toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, 
                        eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitude) ; </li>
                        <li className="cmodal__item">e. conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour 
                        lesquelles elles sont traitées; les données à caractère personnel peuvent être conservées pour des durées plus longues dans la mesure où elles seront traitées exclusivement à des fins 
                        archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques conformément à l'article 89, paragraphe 1, pour autant que soient 
                        mises en oeuvre les mesures techniques et organisationnelles appropriées requises par le règlement afin de garantir les droits et libertés de la personne concernée 
                        (limitation de la conservation) ; </li>
                        <li className="cmodal__item">f. traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, 
                        la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées (intégrité et confidentialité). </li>
                    </ul>

                    <p className="cmodal__txt">Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie : </p>
                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. la personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques ; </li>
                        <li className="cmodal__item">b. le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie ou à l'exécution de mesures précontractuelles prises à la demande de celle-ci ;</li>
                        <li className="cmodal__item">c. le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du traitement est soumis ; </li>
                        <li className="cmodal__item">d. le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ;</li>
                        <li className="cmodal__item">e. le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont est investi 
                        le responsable du traitement ;</li>
                        <li className="cmodal__item">f. le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou 
                        les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant. </li>
                    </ul>

                    <p className="cmodal__txt">Pour les résidents de l'État de Californie, cette politique de confidentialité vise à se conformer à la California Consumer Privacy Act (CCPA). 
                    S'il y a des incohérences entre ce document et la CCPA, la législation de l'État s'appliquera. Si nous constatons des incohérences, nous modifierons notre politique pour nous conformer à 
                    la loi pertinente. </p>

                    <h3 className="cmodal__subtitle">Consentement</h3> 
                    <p className="cmodal__txt">Les utilisateurs conviennent qu'en utilisant notre site, ils consentent à : </p>
                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. les conditions énoncées dans la présente politique de confidentialité et </li>
                        <li className="cmodal__item">b. la collecte, l'utilisation et la conservation des données énumérées dans la présente politique. </li>
                    </ul>

                    <h3 className="cmodal__subtitle">Données  personnelles que nous collectons</h3>
                    <h4 className="cmodal__subsubtitle">Données collectées automatiquement</h4>
                    <p className="cmodal__txt">Lorsque vous visitez et utilisez notre site, nous pouvons automatiquement recueillir et conserver les renseignements suivants : </p>
                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. Lieu </li>
                        <li className="cmodal__item">b. Liens un utilisateur clique tout en utilisant le site </li>
                        <li className="cmodal__item">c. Contenu que l'utilisateur consulte sur votre site </li>
                    </ul>

                    <h4 className="cmodal__subsubtitle">Données recueillies de façon non automatique</h4>
                    <p className="cmodal__txt">Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines fonctions sur notre site : </p>
                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. Email Ces données peuvent être recueillies au moyen des méthodes suivantes : </li>
                    </ul>

                    <h4 className="cmodal__subsubtitle">Inscription Newsletter </h4>
                    <p className="cmodal__txt">Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l'objectif énoncé dans cette politique de confidentialité. <br/>

                    Nous ne recueillerons pas de données supplémentaires sans vous en informer au préalable. </p>

                    <h3 className="cmodal__subtitle">Comment nous utilisons les données personnelles </h3>
                    <p className="cmodal__txt">Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la présente politique ou indiquées sur les pages pertinentes 
                    de notre site. </p>

                    <p className="cmodal__txt">Nous n'utiliserons pas vos données au-delà de ce que nous divulguerons. </p>

                    <p className="cmodal__txt">Les données que nous recueillons automatiquement sont utilisées aux fins suivantes : </p>
                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. Statistique </li>
                    </ul>
                    

                    <p className="cmodal__txt">Les données que nous recueillons lorsque l'utilisateur exécute certaines fonctions peuvent être utilisées aux fins suivantes :</p>
                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. communication </li>
                    </ul> 

                    <h3 className="cmodal__subtitle">Avec qui nous  partageons les données  personnelles</h3>
                    <h4 className="cmodal__subsubtitle">Employés</h4>
                    <p className="cmodal__txt">Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont il a raisonnablement besoin pour réaliser les objectifs énoncés dans 
                    la présente politique. </p>

                    <h4 className="cmodal__subsubtitle">Autres divulgations</h4> 

                    <p className="cmodal__txt">Nous nous engageons à ne pas vendre ou partager vos données avec des tiers, sauf dans les cas suivants : </p>

                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. si la loi l'exige</li>
                        <li className="cmodal__item">b. si elle est requise pour toute procédure judiciaire </li>
                        <li className="cmodal__item">c. pour prouver ou protéger nos droits légaux </li>
                        <li className="cmodal__item"> d. à des acheteurs ou des acheteurs potentiels de cette société dans le cas où nous cherchons à la vendre la société </li>
                    </ul>

                    <p className="cmodal__txt">Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n'avons pas de contrôle sur 
                    leurs politiques et pratiques de confidentialité. </p>

                    <h3 className="cmodal__subtitle">Combien de temps nous stockons les données personnelles  </h3>
                    <p className="cmodal__txt">Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les fins pour lesquelles elles sont recueillies. 
                    Comment nous  protégeons vos données  personnelles  Toutes les données sont utilisées à seul titre de statistique ou de communication. 
                    Ces donnés sont strictement consultable par l'association Just A Cause et stockées dans un système sécurisé.</p>

                    <p className="cmodal__txt">Toutes les données stockées dans notre système sont bien sécurisées et ne sont accessibles qu'à nos employés. 
                    Nos employés sont liés par des accords de confidentialité stricts et une violation de cet accord entraînerait le licenciement de l'employé. </p>

                    <p className="cmodal__txt">Alors que nous prenons toutes les précautions raisonnables pour nous assurer que nos données d'utilisateur sont sécurisées et que les utilisateurs sont protégés, 
                    il reste toujours du risque de préjudice. Internet en sa totalité peut être, parfois, peu sûr et donc nous sommes incapables de garantir la sécurité des données des utilisateurs au-delà 
                    de ce qui est raisonnablement pratique. </p>

                    <h3 className="cmodal__subtitle">Mineurs </h3>
                    <p className="cmodal__txt">Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la collecte de données. 
                    Les mineurs doivent avoir le consentement d'un représentant légal pour que leurs données soient recueillies, traitées et utilisées.</p>

                    <h3 className="cmodal__subtitle">Vos droits en tant qu'utilisateur</h3>
                    <p className="cmodal__txt">En vertu du RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées : </p>
                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. droit d'accès </li>
                        <li className="cmodal__item">b. droit de rectification </li>
                        <li className="cmodal__item">c. droit à l'effacement </li>
                        <li className="cmodal__item">d. droit de restreindre le traitement </li>
                        <li className="cmodal__item">e. droit à la portabilité des données</li>
                        <li className="cmodal__item">f. droit d'objection </li>
                    </ul>

                    <p className="cmodal__txt">Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art 12-23) du RGPD. </p>

                    <h3 className="cmodal__subtitle">Comment modifier, supprimer ou contester les données recueillies </h3>
                    <p className="cmodal__txt">Si vous souhaitez que vos renseignements soient supprimés ou modifiés d'une façon ou d'une autre, veuillez communiquer avec notre agent de protection de la vie privée ici : 
                    contact@justacause.fr </p>

                    <h3 className="cmodal__subtitle">Politique sur les cookies</h3>
                    <p className="cmodal__txt">Un cookie est un petit fichier, stocké sur le disque dur d'un utilisateur par le site Web. 
                    Son but est de recueillir des données relatives aux habitudes de navigation de l'utilisateur.</p>

                    <p className="cmodal__txt">Nous utilisons les types de cookies suivants sur notre site : </p>
                    <ul className="cmodal__list">
                        <li className="cmodal__item">a. Cookies fonctionnels Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu'elles soient sauvegardées pour vos prochaines visites. </li>
                        <li className="cmodal__item">b. Cookies analytiques Cela nous permet d'améliorer la conception et la fonctionnalité de notre site en recueillant des données sur le contenu auquel vous accédez et sur lequel vous vous accrochez en utilisant notre site.</li>
                    </ul>
                    
                    <p className="cmodal__txt">Vous pouvez choisir d'être averti chaque fois qu'un cookie est transmis. 
                    Vous pouvez également choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer la qualité de votre expérience d'utilisation.</p>

                    <h3 className="cmodal__subtitle">Modifications</h3>
                    <p className="cmodal__txt">Cette politique de confidentialité peut être modifiée à l'occasion afin de maintenir la conformité avec la loi et de tenir compte de tout changement à notre processus 
                    de collecte de données. </p>
                    <p className="cmodal__txt">Nous recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s'assurer qu'ils soient informés de toute mise à jour.</p>
                    <p className="cmodal__txt">Au besoin, nous pouvons informer les utilisateurs par courriel des changements apportés à cette politique. </p>

                    <h3 className="cmodal__subtitle">Contact</h3>
                    <p className="cmodal__txt">Si vous avez des questions à nous poser, n'hésitez pas à communiquer avec nous en utilisant ce qui suit : contact@justacause.fr </p>

                    <p className="cmodal__txt">Date d'entrée en vigueur : le 31 mars 2021 </p>

                    <p className="cmodal__txt">© 2002-2021, DocumentsLégauxTM (Sequiter Inc.) </p>

                </div>
            </div>
        </footer>
    )
}

export default Footer;