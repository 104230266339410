import React from "react";
import homeNews1 from '../../../assets/images/jpg/homeNews1.jpg';
import homeNews2 from '../../../assets/images/jpg/homeNews2.jpg';


const NewsHome = () => {
    return(
        <div className="hp-news">
            <div className="hp-news__txt">
                <h2 className="hp-news__title">Actualités</h2>
                <h3 className="hp-news__subtitle hp-news__subtitle--one">Just A Cause c’est quoi ?</h3>
                <div className="hp-news__desc">
                    Just A Cause est une association à but non lucratif reconnue d’intérêt général. Elle apporte conseil, visibilité et force de frappe aux associations à but socio-environnemental. 
                </div>
                <h3 className="hp-news__subtitle hp-news__subtitle--two">Que fait l’association ?</h3>
                <div className="hp-news__desc">
                    Nous mettons en place nos propres actions « coups de poing », visant par exemple à réaménager l’espace urbain afin d’y développer faune et flore, nettoyer nos ruisseaux et littoraux,
                    proclamer l’équité et la liberté des orientations sexuelles et des genres. 
                    En plus d’agir, nous informons textuellement sur les causes défendues et rendons visibles les associations bénéficiaires de notre soutien.
                </div>
                <h3 className="hp-news__subtitle hp-news__subtitle--three">Comment cela fonctionne-t-il ?</h3>
                <div className="hp-news__desc">
                Pour chaque action, nous mettons en place un crowdfunding, sur lequel nous mettons en vente les produits de la marque de l’association Just A Cause. 
                Au terme de la campagne, 60% de la somme récoltée sont destinés à la fabrication des produits, 30% sont utilisés pour mettre en place l’action coup de poing et 10% sont reversés à l’association partenaire de l’action.
                Au-delà de son impact socio-environnemental, Just A Cause permet à tous d’avoir des accessoires éthiques et responsables, sans payer le prix fort ! Et ça, c’est important pour nous.
                Dynamique et volontaire, Just A Cause se trouve au carrefour des associations justes, des entreprises engagées et des particuliers motivés. 
                Il y a forcément une place pour vous, rejoignez-nous !
                </div>
            </div>
            <div className="hp-news__img">
                <img className="hp-news__pic hp-news__pic--one" src={homeNews1} alt="Shoes"/>
                <img className="hp-news__pic hp-news__pic--two" src={homeNews2} alt="Outfit"/>
            </div>
        </div>
    )
}

export default NewsHome;