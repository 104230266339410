import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player/youtube";
import "../css/modules/Articles.css";
import articlePicture1 from "../assets/images/jpg/articlePage.jpg"
import articlePicture2 from "../assets/images/jpg/articlePage2.jpg"
import articlePicture3 from "../assets/images/jpg/articlePage3.jpg"
import articlePicture4 from "../assets/images/jpg/articlePage.jpg"
import axios from "axios";
import { Link } from "react-router-dom";
import SingleArticle from "./items/SingleArticle";

const Articles = () => {

    // const videoPlayer = "https://www.youtube.com/watch?v=EajZaShTAks&feature=youtu.be "
    const videoPlayer = "https://www.youtube.com/watch?v=_rKQ0SZkUE8&feature=youtu.be";

    const [ hookArticle, setHookArticle ] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL + 'article')
            .then((res) => res.data)
            .then((data) => setHookArticle(data));
	}, []);

        const articlePicture = [
            {picture: articlePicture1},
            {picture: articlePicture2},
            {picture: articlePicture3},
            {picture: articlePicture4}
        ]

    return(
        <div className="ap">
            <div className="ap__content">
                <div className="ap__frame">
                <ReactPlayer className="ap__video" url={videoPlayer} loop={true} controls={true} volume={0.5} height="500px" width="80vw"/>
                </div>
                <h2 className="ap__title">Nos articles</h2>
                <div className="ap__block">
                    {hookArticle.map( ( $articles, key ) => (
                        <div className="ap__i" key={key} data-id={$articles.id_article}>
                            <div className="ap__i__pic">
                                <img className="ap__i__img" src={articlePicture[key].picture} 
                                alt="Article cover" />
                            </div>
                            <div className="ap__i__txt">
                                <h4 className="ap__i__title">{$articles.title}</h4>
                                <h5 className="ap__i__subtitle">{$articles.subtitle}</h5>
                                <div className="ap__i__desc">{$articles.description}</div>
                                <div className="ap__i__content">{$articles.accroche}</div>
                                { $articles.is_full ?  
                                <Link className="ap__i__link"
                                    to={{
                                        pathname: `/single-article/${$articles.id_article}`,
                                        params: {article: $articles ? $articles : null}
                                    }} >
                                    <div className="ap__i__text" data-key={key}>
                                        Lire l'article
                                    </div>
                                    <div className="ap__i__arrow">
                                        <span className="ap__i__line ap__i__line--left">Left</span>
                                        <span className="ap__i__line ap__i__line--right">Right</span>
                                    </div>
                                </Link>
                            : ""}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Articles;