import React from "react";
import '../../css/items/Modal.css';

export function displayModal() {
    const $modal = document.getElementById("modal")
    $modal.classList.toggle("js-modal-open")
}

export const Modal = ({ picture, title, content, button  }) => {
    return(
        <div className="modal-bg" id="modal">
            <div className="modal-ct">
                {picture ? <img className="modal-ct__picture" src={picture} alt="Maintenance picto"/> : <br/> }
                <h2 className="modal-ct__title">{title}</h2>
                <div className="modal-ct__content">{content}</div>
                <button type="button" name="close" 
                className="modal-ct__button"
                onClick={() => displayModal()}>{button}</button>
            </div>
        </div>
    );
};
