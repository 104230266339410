import React, { useState } from "react";
import '../../css/items/SingleArticle.css';
import Arrow from "../../assets/images/svg/arrow.svg";
import { Link } from "react-router-dom";


const SingleArticle = ( article ) => {

    const $article = article ? article.history.location.params.article : null;

    window.scrollTo({top: 0, behavior: "smooth"})

    return(
        <div className="sa" id="single-article">
            <div className="sa-nav">
                <Link className="sa-nav__link" to="/articles">
                    -
                </Link>
            </div>
            <div className="sa-hd">
                <h2 className="sa-hd__title sa__title">{$article.title}</h2>
                <div className="sa-hd__desc sa__desc">{$article.description}</div>
                <span className="sa-hd__accroche sa__accroche">{$article.accroche}</span>
            </div>

            <div className="sa-ct">
                <h3 className="sa-ct__subtitle sa__subtitle">{$article.subtitle_one}</h3>
                <p className="sa-ct__txt sa-ct__txt--one sa__txt">{$article.fcontent_one}</p>
                { $article.fcontent_two ?
                    <p className="sa-ct__txt sa-ct__txt--two sa__txt">{$article.fcontent_two}</p>
                :"" }
                { $article.fcontent_three ?
                    <p className="sa-ct__txt sa-ct__txt--three sa__txt">{$article.fcontent_three}</p>
                : "" }

                <h3 className="sa-ct__subtitle sa__subtitle">{$article.subtitle_two}</h3>
                <p className="sa-ct__txt sa-ct__txt--four sa__txt">{$article.scontent_one}</p>
                <p className="sa-ct__txt sa-ct__txt--six sa__txt">{$article.scontent_two}</p>
                
                <h3 className="sa-ct__subtitle sa__subtitle">{$article.subtitle_three}</h3>
                <p className="sa-ct__txt sa-ct__txt--seven sa__txt">{$article.tcontent_one}</p>
                <p className="sa-ct__txt sa-ct__txt--eight sa__txt">{$article.tcontent_two}</p>
                { $article.tcontent_three ?
                    <p className="sa-ct__txt sa-ct__txt--nine sa__txt">{$article.tcontent_three}</p>
                 : "" }
                { $article.tcontent_four ? 
                    <p className="sa-ct__txt sa-ct__txt--ten sa__txt">{$article.tcontent_four}</p>
                : "" }

                
                <h3 className="sa-ct__subtitle sa__subtitle">{$article.subtitle_four ? $article.subtitle_four : ""}</h3>
                <p className="sa-ct__txt sa-ct__txt--eleven sa__txt">{$article.ocontent_one ? $article.ocontent_one : ""}</p>
                <p className="sa-ct__txt sa-ct__txt--twelve sa__txt">{$article.ocontent_two ? $article.ocontent_two : ""}</p>
                <p className="sa-ct__txt sa-ct__txt--thirteen sa__txt">{$article.ocontent_three ? $article.ocontent_three : ""}</p>
                <p className="sa-ct__txt sa-ct__txt--fourteen sa__txt">{$article.ocontent_four ? $article.ocontent_four : ""}</p>
            </div>

            <div className="sa-ft">
                <h3 class="sa-ft__subtitle">Sources :</h3>
                <span class="sa-ft__label">{$article.label_one}</span>
                <a className="sa-ft__link" href={$article.link_one}>{$article.link_one}</a>
                <span class="sa-ft__label">{$article.label_two}</span>
                <a className="sa-ft__link" href={$article.link_two}>{$article.link_two}</a>
                { $article.label_three ? 
                <span class="sa-ft__label">{$article.label_three}</span>
                : ""}
                { $article.link_three ? 
                <a className="sa-ft__link" href={$article.link_three}>
                    {$article.link_three}
                </a> : ""}
                { $article.label_four ? 
                <span class="sa-ft__label">{$article.label_four}</span>
                : ""}
                { $article.link_four ? 
                <a className="sa-ft__link" href={$article.link_four}>
                    {$article.link_four}
                </a> : ""}
            </div>

        </div>
    );
}

export default SingleArticle;