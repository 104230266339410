import React, { useEffect, useState } from "react";
import axios from "axios";
import '../css/modules/Support.css';

const Support = () => {

    const title = "Soutenez votre cause";
    const subtitle = "Grâce à JAC, vous pouvez soutenir votre cause";
    const [ hookSupport, setHookSupport ] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL + 'support')
            .then((res) => res.data)
            .then((data) => setHookSupport(data));
    })

    return(
        <div className="sp">
            <div className="sp__content">
                <h2 className="sp__title">{title}</h2>
                <h3 className="sp__subtitle">{subtitle}</h3>
                <div className="sp__block">
                    {hookSupport.map( ( $support, key ) => (
                        <div className="sp__i" key={key}>
                            <div className="sp__i__head">
                                <h4 className="sp__i__title">{$support.title}</h4>
                                <h5 className="sp__i__subtitle">{$support.subtitle}</h5>
                            </div>
                            <div className="sp__i__content">
                                <div className="sp__i__txt">{$support.content}</div>
                            </div>
                            <div className="sp__i__foot">
                                <a href={`${$support.link}`} className="sp__i__link">Lien de la cause</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Support;