import React, { useState, useEffect } from "react";
import axios from "axios";
import '../css/modules/Events.css';

const Event = () => {

    const [ hookEvent, setHookEvent ] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL + 'event')
            .then((res) => res.data)
            .then((data) => setHookEvent(data));
    }, []);

    const dayDisplay = ( input ) => {
        return input.substring(8, 10);
    }

    const monthDisplay = ( input ) => {
        return input.substring(5, 7);
    }

    const yearDisplay = ( input ) => {
        return input.substring(0, 4);
    }

    return(
        <div className="ep">
            <div className="ep__content">
                <h2 className="ep__title">Nos évènements</h2>
                <div className="ep__block">
                    {hookEvent.map( ( $event, key ) => (
                        <div className="ep__i" key={key}>
                            <div className="ep__i__pic">
                                <img className="ep__i__img" src={$event.picture} alt="Event cover"/>
                                <div className="ep__i__block">
                                    <div className="ep__i__date">{dayDisplay( $event.date )}</div>
                                    <div className="ep__i__date">{monthDisplay( $event.date )}</div>
                                    <div className="ep__i__date">{yearDisplay( $event.date )}</div>
                                </div>
                                <a className="ep__i__link" href="#event"> SOUTENEZ VOTRE CAUSE</a>
                            </div>
                            <div className="ep__i__txt">
                                <h4 className="ep__i__title">{$event.title}</h4>
                                <div className="ep__i__subtitle">{$event.subtitle}</div>
                                <div className="ep__i__content">{$event.content}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Event;