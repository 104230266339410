import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../components/Home";
import History from "../components/History";
import PartnerA from "../components/PartnerA";
import PartnerB from "../components/PartnerB";
import Support from "../components/Support";
import Event from "../components/Event";
import Article from "../components/Articles";
import SingleArticle from "../components/items/SingleArticle";
import Contact from "../components/Contact";
import Login from "../components/auth/Login";
// import Register from "../components/auth/Register";
import { AuthContext }  from "../components/auth/Auth";
// import PrivateRoute from "../components/auth/PrivateRoute";
// import BOHome from "../components/backoffice/BOHome";
// import BOArticles from "../components/backoffice/BOArticles";
// import BOContact from "../components/backoffice/BOContact";
// import BOEvent from "../components/backoffice/BOEvent";
// import BOHistory from "../components/backoffice/BOHistory";
// import BONews from "../components/backoffice/BONews";
// import BOPartner from "../components/backoffice/BOPartner";
// import BOSupport from "../components/backoffice/BOSupport";

import LegalNotice from "../components/LegalNotice";
import TermsOfService from "../components/TermsOfService";

import Header from "../components/inline/Header";
import Footer from "../components/inline/Footer";
import Error404 from "../components/error/Error404";

const Routes = () => {

    const [ authTokens, setAuthTokens ] = useState();
    const [ dataAuth, setDataAuth ] = useState();
    const [ userInfo, setUserInfo ] = useState();


    const setTokens = data => {
        localStorage.setItem('tokens', JSON.stringify(data));
        setAuthTokens(data);
    }

    return(
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, dataAuth, setDataAuth, userInfo, setUserInfo }}>

            <Router>
                <Header />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/histoire" component={History} />
                    <Route path="/partenaires-associations" component={PartnerA} />
                    <Route path="/partenaires-entreprises" component={PartnerB} />
                    <Route path="/supports" component={Support} />
                    <Route path="/evenements" component={Event} />
                    <Route path="/articles" component={Article} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/conditions-generales" component={TermsOfService}/>
                    <Route path="/mentions-legales" component={LegalNotice} />
                    <Route path="/AdminPanelLogin" component={Login} />
                    {/* <Route path="/single-article/:id_article" render={ ( props ) => {
                        return ( <SingleArticle { ...props } /> )
                    } } /> */}
                    <Route path="/single-article/:id_article" component={SingleArticle} />
                    {/* <Route path="/Register" component={Register} /> 
                    <Route path="/AdminPanelHome" component={BOHome} />
                    <Route path="/AdminPanelArticles" component={BOArticles} />
                    <Route path="/AdminPanelContact" component={BOContact} />
                    <Route path="/AdminPanelEvents" component={BOEvent} />
                    <Route path="/AdminPanelHistory" component={BOHistory} />
                    <Route path="/AdminPanelNews" component={BONews} />
                    <Route path="/AdminPanelPartners" component={BOPartner} />
                    <Route path="/AdminPanelSupport" component={BOSupport} />
                    */}
                    <Route path="/*" component={Error404}/>
                </Switch>
                <Footer />
            </Router>

        </AuthContext.Provider>
    )
}

export default Routes;